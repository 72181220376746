import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { addTenantToUrl } from '../../services/service.config'

import accountSummary from '../../assets/account-summary.svg'
import personalDetails from '../../assets/personal-details.svg'
import assignedProducts from '../../assets/assigned-products.svg'
import companyDetails from '../../assets/company-details.svg'
import myOrders from '../../assets/my-orders.svg'
import myQuotes from '../../assets/my-quotes.svg'
import replenishmentOrders from '../../assets/replenishment-orders.svg'
import savedCarts from '../../assets/saved-carts.svg'
import returns from '../../assets/returns.svg'
import discounts from '../../assets/discounts.svg'
import locations from '../../assets/locations.svg'
import payments from '../../assets/payments.svg'
import manageUsers from '../../assets/manage-users.svg'
import {
  SEGMENTS_ASSIGNED_PRODUCTS_KEY,
  useSegments,
} from 'context/segment-provider'

const AccountMenu = ({ page, className }) => {
  const { segmentAssignedProducts } = useSegments()
  const [filteredItems, setFilteredItems] = useState(null)

  const hasAssignedProducts = () => {
    const segmentAssignedProducts = JSON.parse(
      localStorage.getItem(SEGMENTS_ASSIGNED_PRODUCTS_KEY)
    )

    return segmentAssignedProducts?.length > 0
  }

  const items = [
    {
      name: 'My Account',
      link: 'account-summary',
      icon: accountSummary,
    },
    {
      name: 'Personal Details',
      link: 'personal-details',
      icon: personalDetails,
    },
    {
      name: 'Assigned Products',
      link: 'assigned-products',
      icon: assignedProducts,
      renderFunction: hasAssignedProducts,
    },
    {
      name: 'Company Details',
      link: 'company-details',
      icon: companyDetails,
    },
    {
      name: 'Manage Users',
      link: 'manage-users',
      icon: manageUsers,
      scopes: 'customer.customer_read_own',
    },
    {
      name: 'Addresses',
      link: 'addresses',
      icon: locations,
    },
    {
      name: 'My Orders',
      link: 'my-orders',
      icon: myOrders,
    },
    {
      name: 'Company Orders',
      link: 'legal-entity-orders',
      icon: myOrders,
    },
    {
      name: 'My Quotes',
      link: 'my-quotes',
      icon: myQuotes,
    },
    {
      name: 'Replenishment Orders',
      link: 'replenishment-orders',
      icon: replenishmentOrders,
    },
    {
      name: 'Saved Carts',
      link: 'saved-carts',
      icon: savedCarts,
    },
    {
      name: 'My Returns',
      link: 'returns',
      icon: returns,
    },
    {
      name: 'My Discounts',
      link: 'discounts',
      icon: discounts,
    },
    {
      name: 'My Subscriptions',
      link: 'my-subscriptions',
      icon: myOrders,
    },
    {
      name: 'Locations',
      link: 'locations',
      icon: locations,
    },
    {
      name: 'Payment Methods',
      link: 'payments',
      icon: payments,
    },
  ]

  useEffect(() => {
    const resolvedItems = items
      .filter((item) => hasRequiredScopes(item.scopes))
      .filter((item) =>
        shouldBeRendered(item.renderFunction, segmentAssignedProducts)
      )
    setFilteredItems(resolvedItems)
  }, [segmentAssignedProducts])

  const hasRequiredScopes = (scopes) => {
    if (scopes) {
      const scopes = localStorage.getItem('scopes')
      if (scopes) {
        return scopes.includes(scopes)
      }
      return false
    }
    return true
  }

  const shouldBeRendered = (renderFunction) => {
    if (renderFunction) {
      return renderFunction()
    }
    return true
  }

  return (
    <ul className={className}>
      {filteredItems &&
        filteredItems.map((value, index) =>
          value !== '' ? (
            index === 0 ? (
              page === 'Index' ? (
                <Link
                  to={addTenantToUrl(`my-account/${value.link}`)}
                  key={value.name}
                >
                  <li
                    className="index-item-active first-item flex items-center"
                  >
                    <img src={value.icon} className="mr-4" alt={value.link} />
                    {value.name}
                  </li>
                </Link>
              ) : (
                <Link
                  to={addTenantToUrl(`my-account/${value.link}`)}
                  key={value.name}
                >
                  <li
                    className={
                      value === page
                        ? 'item-active first-item flex items-center'
                        : 'first-item flex items-center'
                    }
                  >
                    <img src={value.icon} className="mr-4" alt={value.link} />
                    {value.name}
                  </li>
                </Link>
              )
            ) : (
              <Link to={addTenantToUrl(`my-account/${value.link}`)} key={value.name}>
                <li
                  className={
                    value === page
                      ? 'item-active item flex items-center'
                      : 'item flex items-center'
                  }
                >
                  <img src={value.icon} className="mr-4" alt={value.link} />
                  {value.name}
                </li>
              </Link>
            )
          ) : (
            <li key={index} className="item" />
          )
        )}
    </ul>
  )
}

export default AccountMenu
