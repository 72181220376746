import React, { useCallback, useEffect, useState } from 'react'
import AccountLayout from './AccountLayout'
import { GridLayout } from '../../components/Utilities/common'
import { getLegalEntityOrder } from '../../services/orders.service'
import { useParams } from 'react-router-dom'
import OrderDetails from './OrderDetails'
import { myAccountLegalEntityOrderViewUrl } from '../../services/service.config'
import { BackButton } from '../../components/Utilities/button'
import { useLegalEntity } from 'context/legal-entity-provider'
const MyOrdersView = () => {
  const [order, setOrder] = useState({})
  const { orderId } = useParams()
  const { currentLegalEntity } = useLegalEntity()

  const fetchedOrder = useCallback(async () => {
    const fetchedOrder = await getLegalEntityOrder(currentLegalEntity.id, orderId)
    setOrder(fetchedOrder)
  }, [currentLegalEntity, orderId])

  useEffect(() => {
    fetchedOrder()
  }, [])

  return (
    <GridLayout className="mt-9 gap-12">
      <div className="pb-6 border-b border-bgWhite">
        <div className="lg:block hidden">
          <OrderDetails order={order} />
        </div>
      </div>
    </GridLayout>
  )
}

const AccountLegalEntityOrderView = () => {
  const { orderId } = useParams()

  return (
    <AccountLayout page="View" detail={orderId}>
      <BackButton link={myAccountLegalEntityOrderViewUrl()} title={'Back to company orders list'} />
      <MyOrdersView />
    </AccountLayout>
  )
}
export default AccountLegalEntityOrderView
