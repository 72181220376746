import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import { useAuth } from './auth-provider'
import { getCustomerDetails } from '../services/customer.service'
import { LoadingCircleProgress } from 'components/Utilities/progress'
import { useSites } from './sites-provider'

export const CURRENT_LEGAL_ENTITY_KEY = 'current-legal-entity'

const initialContext = {
  legalEntities: [],
  currentLegalEntity: null, 
  currentBusinessModel: null,
  setLegalEntity: () => {},
}

const deduplicateLegalEntities = (entities) => {
  const uniqueEntities = new Map()

  entities.forEach((entity) => {
    const key = `${entity.id}-${entity.name}`
    if (!uniqueEntities.has(key)) {
      uniqueEntities.set(key, entity)
    }
  })

  return Array.from(uniqueEntities.values())
}

const LegalEntitiesContext = createContext(initialContext)
export const useLegalEntity = () => useContext(LegalEntitiesContext)

export const LegalEntityProvider = ({ children }) => {
  const [currentLegalEntity, setCurrentLegalEntity] = useState(null)
  const [currentBusinessModel, setCurrentBusinessModel] = useState(null)
  const [legalEntities, setLegalEntities] = useState([])
  const { isLoggedIn, userTenant, refreshToken } = useAuth()
  const [loading, setLoading] = useState(true)

  const setLegalEntity = useCallback(async (legalEntity) => {
      await refreshToken(legalEntity.id)
      setCurrentLegalEntity(legalEntity)
      localStorage.setItem(CURRENT_LEGAL_ENTITY_KEY, JSON.stringify(legalEntity))
  }, [])

  const value = useMemo(
    () => ({
      legalEntities,
      currentLegalEntity,
      currentBusinessModel,
      setLegalEntity,
    }),
    [legalEntities, currentLegalEntity, currentBusinessModel, setLegalEntity]
  )

  const fetchLegalEntities = async () => {
    try {
      if (isLoggedIn && userTenant) {
        const customerData = await getCustomerDetails(userTenant)
        const rawLegalEntities = customerData?.b2b?.legalEntities

        setCurrentBusinessModel(customerData.businessModel)
        if (
          customerData.businessModel === 'B2B' &&
          rawLegalEntities?.length > 0
        ) {
          const uniqueLegalEntities = deduplicateLegalEntities(rawLegalEntities)
          setLegalEntities(uniqueLegalEntities)

          const stored = localStorage.getItem(CURRENT_LEGAL_ENTITY_KEY)
          if (stored) {
            setCurrentLegalEntity(JSON.parse(stored))
          } else {
            setLegalEntity(uniqueLegalEntities[0])
          }
        }
      }
    } catch (error) {
      console.error('Error fetching legal entities:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchLegalEntities()
  }, [isLoggedIn, userTenant, setLegalEntity])

  useEffect(() => {
    if (!isLoggedIn) {
      setLegalEntities([])
      setCurrentLegalEntity(null)
      setCurrentBusinessModel(null)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isLoggedIn && currentLegalEntity) {
      const timer = setTimeout(async () => {
        await refreshToken(currentLegalEntity.id)
      }, 500); 
      return () => clearTimeout(timer); 
    }
  }, [isLoggedIn, currentLegalEntity, refreshToken])

  if (loading) {
    return <LoadingCircleProgress />
  }

  return (
    <LegalEntitiesContext.Provider value={value}>
      {children}
    </LegalEntitiesContext.Provider>
  )
}
