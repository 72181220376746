import React, { useCallback, useEffect, useState } from 'react'
import AccountLayout from './AccountLayout'
import { OrderList } from './OrdersList'
import { getLegalEntityOrders } from '../../services/orders.service'
import { useLegalEntity } from 'context/legal-entity-provider'

const AccountLegalEntityOrders = () => {
  const [orders, setOrders] = useState([])
  const [error, setError] = useState(null)
  const { currentLegalEntity } = useLegalEntity()

  const fetchOrders = useCallback(async () => {
    try {
      const fetchedOrders = await getLegalEntityOrders(currentLegalEntity.id)
      setOrders(fetchedOrders)
      setError(null)
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setError('You do not have access to Company Orders.')
      } else {
        setError('An error occurred while fetching orders.')
        console.error('Error fetching orders:', err)
      }
    }
  }, [currentLegalEntity.id])

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  return (
    <AccountLayout page="Legal Entity Orders">
      {error ? (
        <div className="error-message-box">
          <p>{error}</p>
        </div>
      ) : (
        <OrderList orders={orders} invoiceAvailable={true} legalEntityId={currentLegalEntity.id} />
      )}
    </AccountLayout>
  )
}

export default AccountLegalEntityOrders
